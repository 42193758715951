/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import ButtonAgrofy from '../../atoms/ButtonAgrofy/ButtonAgrofy';
import CheckboxFilters from './CheckboxFilters/CheckboxFilters';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import { format, subYears } from 'date-fns';
import getData from '../../services/GetData';
import { Helmet } from 'react-helmet-async';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import RadioFilters from './RadioFilters/RadioFilters';
import SearchResultCard from './SearchResultCard/SearchResultCard';
import { useTranslation } from 'react-i18next';
import withSSR from '../../withSSR';
import {gtmInitialize, manageGTMPageName, manageGTMUserStatus} from './../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import './SearchResults.scss';
import DarkModeContext from '../../atoms/DarkModeContext/DarkModeContext';
import UserContext from '../../atoms/UserContext/UserContext';

const PAGE_AMOUNT = 20;

const SearchResults = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isDarkMode, toggleMode } = useContext(DarkModeContext);
  const serverData = props && props.serverData ? props.serverData : false;
  const [data, setData] = useState(serverData || null);
  const initialQuery =
    props && props.match && props.match.params && props.match.params.filtros;
  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const [newSearchQuery, setNewSearchQuery] = useState();
  const [numberOfResults, setNumberOfResults] = useState(0);
  const envURL =
    process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;

  const [sectionsToFilter, setSectionsToFilter] = useState([]);
  const [selected, setSelected] = useState({
    fromDate: subYears(new Date(), 20),
    toDate: new Date(),
  });

  const [showFilters, setShowFilters] = useState('hide');
  const [loading, setLoading] = useState(false);
  const loader = (
    <div className="loader p-0">
      <div className="animated-background" />
    </div>
  );
  const noData = (
    <div className="no-data d-flex">
      <i className="icon-magnifier"></i>
      <div className="no-data-text">
        <h2>{t('no_data')}</h2>
        <p>{t('try_other_filter')}</p>
      </div>
    </div>
  );
  const breadcrumb = [{ title: `${t('search_results')}`, link: '' }];

  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [amount, setAmount] = useState(null);

  const dfpNetworkID =
    process.env.DFP_NETWORK_ID ||
    (typeof window !== 'undefined' && window.env.DFP_NETWORK_ID);

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    if (!(data && data.result)) {
      return;
    }
    setCurrentPage(1);
    setData(formatData());
  }, [data]);

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    //setLoading(true);
    setSearchQuery(initialQuery);
    window.scrollTo(0, 0);

    window.onload = () => {
      setTimeout(() => {
        gtmInitialize();
      }, 3000)
    };

    const root = document.getElementById('root');
    root && root.classList.add('hide-search');
    return () => {
      root && root.classList.remove('hide-search');
    };
  }, []);

  useEffect(() => {
    if (window) {
      manageGTMPageName('Home Resultados de búsqueda');
    }
  }, []);

  useEffect(() => {
    var overflow = showFilters === 'hide' ? 'unset' : 'hidden';
    document.body.style.overflow = overflow;
  }, [showFilters]);

  const handleKeyDown = (event) => {
    if (validApplyFilters() && event.key === 'Enter') {
      applyFilters();
    }
  };

  const formatData = () => {
    let init = 0;
    const newData = data && data.result;
    const count = Math.ceil(newData.length / PAGE_AMOUNT);
    setNumberOfResults(data && data.count);
    setAmount(count);
    const arr = Array(count)
      .fill()
      .map(() => {
        const sub = newData.slice(init, (init += PAGE_AMOUNT));
        return sub;
      });
    return arr;
  };

  const applyFilters = () => {
    const fromDate = format(selected.fromDate, 'yyyyMMdd');
    const toDate = format(selected.toDate, 'yyyyMMdd');

    let params = selected ? `dateIni=${fromDate}&dateEnd=${toDate}` : '';
    params += sectionsToFilter.length ? `&categories=${sectionsToFilter.toString()}` : '';

    history.push({
      pathname: `/search/${searchQuery}`,
      search: params
    });

    window.location.reload();
  };

  const validApplyFilters = () => {
    return !loading && searchQuery && searchQuery.length > 2;
  };

  const handleChangeRadio = (date) => {
    setSelected({
      ...selected,
      fromDate: date.from || selected.fromDate,
      toDate: date.to || selected.toDate,
    });
  };

  const handleChangeInput = (inputId) => {
    sectionsToFilter.includes(inputId)
      ? setSectionsToFilter(sectionsToFilter.filter((item) => item !== inputId))
      : setSectionsToFilter(sectionsToFilter.concat(inputId));
  };

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <div className="page search-result-page container px-0 pb-20">
        <Helmet>
          <title>Resultados de búsqueda | Agrofy News</title>

          <meta name="robots" content="noindex,follow" />
        </Helmet>

        <AgroPushNotification location="Section" />
        <section className="dfp-banner stripped container top-banner">
          <AdSlot
            adUnit={`home_header`}
            sizes={[
              [970, 90],
              [970, 250],
              [320, 80],
            ]}
          />
        </section>
        <section id="main-section" className="d-flex flex-wrap mt-20">
          <div className="fluid-container col-lg-9 px-0">
            <section className="breadcrumb-wrapper p-0 mb-15">
              <Breadcrumb breadcrumbLinks={breadcrumb} />
            </section>
            <section className="container">
              <h1>{t('search_results')}</h1>
              {!data ? (
                <span className="amount-loader">{loader}</span>
              ) : loading ? (
                <span className="amount-loader">{loader}</span>
              ) : (
                <p className="mb-0">
                  {t('we_found')} {numberOfResults} {t('results_for')}{' '}
                  {newSearchQuery || initialQuery}
                </p>
              )}
            </section>
            <section className="search-result-page__results-wrapper">
              <span
                className="show-filter d-md-none"
                onClick={() => setShowFilters('show')}
              />
              <div className="d-flex mt-20">
                <div
                  className={`${showFilters} search-result-page__results-wrapper__filters col-12 col-md-4 col-lg-3 px-10 py-15 ml-15`}
                >
                  <span
                    className="close-filters d-md-none"
                    onClick={() => setShowFilters('hide')}
                  >
                    ×
                  </span>
                  <div className="search-result-page__search-input">
                    <input
                      className="form-control open-sans"
                      type="text"
                      autoComplete="off"
                      name="search-input"
                      value={searchQuery}
                      onChange={(e) =>
                        setSearchQuery(
                          e.target.value.trim().replace(/\s\s+/g, ' ')
                        )
                      }
                      onKeyPress={(e) => handleKeyDown(e)}
                    />
                    <span className="search-icon">
                      <i className="magnifierIcon"></i>
                    </span>
                  </div>
                  <span className="my-15 d-flex">
                    {t('dates_range')} <br />
                  </span>
                  <RadioFilters
                    parentCallback={(date) => handleChangeRadio(date)}
                  />
                  <hr />
                  <span className="mb-15 d-flex">
                    {t('sections')} <br />
                  </span>
                  {!data ? (
                    loader
                  ) : (
                    <div className="search-result-page__results-wrapper__filters__sections">
                      <CheckboxFilters
                        parentCallback={(sectId) => handleChangeInput(sectId)}
                      />
                    </div>
                  )}
                  <ButtonAgrofy
                    title={t('apply')}
                    parentCallback={applyFilters}
                    disabled={!validApplyFilters()}
                    classes="primary small w-100 open-sans mt-15"
                  />
                </div>
                <div className="search-result-page__results-wrapper__results col-12 col-md-8 col-lg-9 px-20 open-sans">
                  {!data || loading
                    ? loader
                    : numberOfResults === 0
                    ? noData
                    : data &&
                      data[currentPage - 1] &&
                      data[currentPage - 1].map((item) => (
                        <a
                          href={`/${item.url}`}
                          key={item.id}
                          className="search-result-page__results-wrapper__results__item"
                        >
                          <SearchResultCard
                            item={item}
                            isDarkMode={isDarkMode}
                            toggleMode={toggleMode}
                          />
                        </a>
                      ))}
                  {numberOfResults > PAGE_AMOUNT && !loading && (
                    <div className="my-36">
                      {currentPage && amount && (
                        <PaginationAgrofy
                          parentCallback={setCurrentPage}
                          currentPage={currentPage}
                          amount={amount}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <AdSlot adUnit={`home_followme`} sizes={[[260, 600]]} />
            </div>
          </div>
        </section>
      </div>
    </DFPSlotsProvider>
  );
};

SearchResults.getInitialProps = async ({ match, req, res }) => {
  const url =
    process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
  const query = match.params.filtros.replace('?', '&');
  try {    
    const { data } = await getData({
      url: `${url}/api/Search/NewsSearchResult?search=${query}`,
    });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(SearchResults);
