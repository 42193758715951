import React, {useState, useEffect, useContext} from 'react';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import SectionTemplate from '../../templates/sectionTemplate/SectionTemplate';
import TableDollarAndStocks from '../../atoms/Tables/TableDollarAndStocks/TableDollarAndStocks';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import {gtmInitialize, manageGTMPageName, manageGTMUserStatus} from './../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import withSSR from '../../withSSR';
import getData from '../../services/GetData';
import IolLogo from '../../atoms/IolLogo/IolLogo';
import UserContext from '../../atoms/UserContext/UserContext';

function EconomicIndicators(props) {
  const { t } = useTranslation();
  const pathName = props ? props.location.pathname : false;
  const serverData = props && props.serverData;
  const [updatedData, setUpdatedData] = useState(serverData || null);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const amount = (serverData && serverData.pages);

  const templateBanners = [
    {
      adUnit: 'economia_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'economia_middle_2',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'economia_middle_3',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'economia_filmstrip',
      sizes: [[300, 600]]
    }
  ];
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;
  
  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    if(window) {
      manageGTMPageName(`Home Economía y política Dólar`);
    }
  }, []);

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true)
    return getData({ url: `${url}/api/News/GetNewsListByCategory?categoryID=28319&page=${currentPage - 1}&limit=15&canonical=${pathName}` })
      .then(result => {
        setUpdatedData(result.data)
        setIsLoading(false)
      });
  };

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (updatedData || serverData) {
      const section = document.getElementById('section-template');
      setIsLoading(false);
      if (section && ((serverData !== updatedData) || (currentPage !== previousPage))) {
        setPreviousPage(currentPage);
        fetchData();
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <div className="economic-indicators">
        <Helmet>
          <title>{serverData && serverData.metaTitle}</title>
          <meta name="robots" content="index,follow" />
          <link rel="canonical" href={serverData && serverData.canonical} />
          <meta name="description" content={serverData && serverData.metaDescription} />
          <script type="application/ld+json">{serverData && serverData.breadcrumb && buildBreadcrumbJson(getBreadcrumbData(serverData.breadcrumb, false))}</script>
        </Helmet>
        <AgroPushNotification location="Section" />
        <div className="economic-indicators-page container px-0">
          <section className="dfp-banner stripped container top-banner">
            <AdSlot adUnit={'economia_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
          </section>
          <section id="main-section" className="d-flex flex-wrap mt-20">
            <div className="fluid-container col-lg-9 px-0">
              <section className="breadcrumb-wrapper p-0 mb-10">
                <Breadcrumb breadcrumbLinks={getBreadcrumbData(serverData && serverData.breadcrumbNav, true)} />
              </section>
              <section className="general-interest container">
                <h1 className="mb-10">{serverData && serverData.seoTitle}</h1>
                <p>{serverData && serverData.seoText}</p>
                <div className="economic-indicators-content">
                  <TableDollarAndStocks showStocks />
                  <IolLogo />
                  <SectionTemplate category='Economia politica' data={updatedData || serverData} bannersData={templateBanners} sectionTitle={t('keep_reading')} loading={isLoading} />
                  <div className="mb-36">
                    {currentPage && amount &&
                      <PaginationAgrofy
                        parentCallback={setCurrentPage}
                        currentPage={currentPage}
                        amount={amount}
                      />
                    }
                  </div>
                </div>
              </section>
            </div>
            <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
              <div className="make-me-sticky">
                <AdSlot adUnit={'economia_noticia_followme'} sizes={[[260, 600]]} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </DFPSlotsProvider>
  );
}

EconomicIndicators.getInitialProps = async ({ match, req, res }) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const pathName = match && match.url;

  try {
    const {
      data
    } = await getData({ url: `${url}/api/News/GetNewsListByCategory?categoryID=28319&page=0&limit=15&canonical=${pathName}` });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(EconomicIndicators);
