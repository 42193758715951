import React, { useState, useEffect, useContext } from 'react';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import {DFPSlotsProvider, AdSlot} from 'react-dfp';
import getData from '../../services/GetData';
import {Helmet} from 'react-helmet-async';
import { Link } from 'react-router-dom';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import RainTabs from './RainTabs/RainTabs';
import SectionTemplate from '../../templates/sectionTemplate/SectionTemplate';
import {useTranslation} from 'react-i18next';
import WeatherModal from '../../atoms/WeatherModal/WeatherModal';
import withSSR from '../../withSSR';
import './Lluvias.scss';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import {splitUrl} from './../../utils/stringUtils';
import {gtmInitialize, manageGTMPageName, manageGTMUserStatus} from './../../utils/GTMDataLayerFuncs';
import UserContext from '../../atoms/UserContext/UserContext';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';

function Lluvias(props) {
  const serverData = props && props.serverData;
  const pathName = props ? props.location.pathname : false;
  // const windowEnvRAZZLE_ENDPOINT_NEWS = 'https://news.agrofy.com.ar';
  const [templateData, setTemplateData] = useState(serverData || null);
  const [rainsData, setRainsData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const amount = serverData && serverData.pages;
  const {t} = useTranslation();

  const loader = <div className="loader container p-0"><div className="animated-background" /></div>;

  const templateBanners = [
    {
      adUnit: 'clima_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'clima_middle_2',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'clima_middle_3',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'clima_filmstrip',
      sizes: [[300, 600]]
    }
  ];
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true)
    return getData({url: `${url}/api/News/GetNewsListByCategory?categoryID=2816&page=${currentPage - 1}&limit=15&canonical=${pathName}`})
      .then(result => {
        setTemplateData(result.data)
        setIsLoading(false)
      });
  };
  
  const fetchRainsData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
    setIsLoading(true)
    return getData({url: `${url}/api/Weather/GetRains`})
      .then(result => {
        setRainsData(result.data)
        setIsLoading(false)
      });
  };

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    fetchRainsData()
    if (templateData || rainsData) {
      const section = document.getElementById('section-template');
      fetchData();
      if (section && (rainsData !== templateData)) {
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if(window && rainsData) {
      manageGTMPageName('Home Lluvias y temperaturas');
    }
  }, [rainsData]);

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <div className="clima-tab lluvias container px-0">
        <Helmet>
          <title>{serverData && serverData.metaTitle}</title>
          <meta name="robots" content="index,follow" />
          <link rel="canonical" href={serverData && serverData.canonical} />
          <meta name="description" content={serverData && serverData.metaDescription} />
          <script type="application/ld+json">{serverData && serverData.breadcrumb && buildBreadcrumbJson(getBreadcrumbData(serverData.breadcrumb, false))}</script>
        </Helmet>
        <AgroPushNotification location="Section" />
        <section className="dfp-banner stripped container top-banner">
          <AdSlot adUnit={'clima_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
        </section>
        <section id="main-section" className="d-flex flex-wrap mt-20">
          <div className="fluid-container col-lg-9 px-0">
            <section className="breadcrumb-wrapper p-0">
              <Breadcrumb breadcrumbLinks={getBreadcrumbData(serverData && serverData.breadcrumbNav, true)} />
            </section>
            <section className="pages-togler">
              <ul>
                <li><Link to="/pronostico-clima-tiempo">{t('forecast')}</Link></li>
                <li className="active"><Link to="/pronostico-clima-tiempo/lluvias-temperaturas">{t('rains')}</Link></li>
                <li><Link to="/pronostico-clima-tiempo/humedad">{t('humidity')}</Link></li>
                <li><Link to="/pronostico-clima-tiempo/imagen-satelital">{t('satelital_image')}</Link></li>
              </ul>
            </section>
            <section className="container">
              <h1>{serverData && serverData.seoTitle}</h1>
              <p>{serverData && serverData.seoText}</p>
              <div>
                <div className="maps-container rains-wrapper">
                  <section className="modals">
                    <ul>
                      <li>
                        {!rainsData ? loader :
                          <WeatherModal
                            title={t('map_of_rains')}
                            mapImage={rainsData.rain_map}
                            mapReference={rainsData.rain_map_ref}
                          />
                        }
                      </li>
                      <li>
                        {!rainsData ? loader :
                          <WeatherModal
                            title={t('map_of_temp_min')}
                            mapImage={rainsData.min_map}
                            mapReference={rainsData.min_map_ref}
                          />
                        }
                      </li>
                      <li>
                        {!rainsData ? loader :
                          <WeatherModal
                            title={t('map_of_temp_max')}
                            mapImage={rainsData.max_map}
                            mapReference={rainsData.max_map_ref}
                          />
                        }
                      </li>
                    </ul>
                  </section>
                  <section className="w-100 mt-36 d-flex">
                    <div className="rains-comment-wrapper w-100">
                      <span className="title d-flex w-100">{t('comment_map_of_rains')}</span>
                      <span className="text2 comment d-flex mt-10">
                        {!rainsData ? loader : rainsData.comment}
                      </span>
                    </div>
                  </section>
                  <section className="rains-tabs-maps mt-36">
                    <span className="title d-flex mb-10">{t('map_of_rains')}</span>
                    <RainTabs />
                  </section>
                </div>
              </div>
              <SectionTemplate category='Clima' data={templateData || serverData} bannersData={templateBanners} sectionTitle={t('keep_reading')} loading={isLoading}/>
              <div className="mb-36">
                {currentPage && amount &&
                  <PaginationAgrofy
                    parentCallback={setCurrentPage}
                    currentPage={currentPage}
                    amount={amount}
                  />
                }
              </div>
            </section>
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <AdSlot adUnit={'clima_followme'} sizes={[[260, 600]]} />
            </div>
          </div>
        </section>
        <AdhesionBanner adUnit='clima_adhesion' sizes={[[320, 80]]} />
        <PopUpBanner adUnit='clima_fullscreen' sizes={[[800, 600], [320, 480]]} />
      </div>
    </DFPSlotsProvider>
  );
}

Lluvias.getInitialProps = async ({match, req, res}) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const pathName = match && match.url;
  try {
    const {
      data
    } = await getData({url: `${url}/api/News/GetNewsListByCategory?categoryID=2816&page=0&limit=15&canonical=${pathName}`});
    return {serverData: data};
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(Lluvias);
