/* eslint-disable no-confusing-arrow */
import React, { useEffect, useContext } from 'react';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Video from '../../components/Video/Video';
import { gtmInitialize, manageGTMPageName, manageGTMUserStatus } from './../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import './Videos.scss';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import UserContext from '../../atoms/UserContext/UserContext';

const Videos = (props) => {
  const { t } = useTranslation();
  const pathName = props ? props.location.pathname : false;

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    window.onload = () => {
      setTimeout(() => {
        gtmInitialize();
      }, 3000)
    };
  }, []);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    if (window) {
      manageGTMPageName('Home Videos');
    }
  }, []);

  const breadcrumbArr = [{ level: 'Agrofy News' }, { level: 'Videos', urlLevel: pathName.replace(/^\/|\/$/g, '') }];
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <div className="videos">
        <Helmet>
          <title>Videos de la agroindustria | Agrofy News</title>
          <meta name="robots" content="index,follow" />
          <link rel="canonical" href="https://news.agrofy.com.ar/videos-de-la-agroindustria" />
          <script type="application/ld+json">{buildBreadcrumbJson(breadcrumbArr)}</script>
        </Helmet>
        <AgroPushNotification location="Section" />
        <section className="dfp-banner stripped container top-banner">
          <AdSlot adUnit={'videos_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
        </section>
        <section id="main-section" className="container p-0 d-flex flex-wrap mt-20">
          <div id="video-section" className="fluid-container col-lg-8 px-0">
            <h1 className="px-15 my-15">{t('best_agro_industry_videos')}</h1>
            <p>Los mejores videos sobre el mundo de la agroindustria en un solo lugar.</p>
            <Video isFull={true} />
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <AdSlot adUnit={'videos_followme'} sizes={[[260, 600]]} />
            </div>
          </div>
        </section>
      </div>
    </DFPSlotsProvider>
  );
};

export default Videos;
