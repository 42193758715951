import React, {useState, useEffect, useContext} from 'react';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import Calendar from '../../atoms/Calendar/Calendar';
import {DFPSlotsProvider, AdSlot} from 'react-dfp';
import getData from '../../services/GetData';
import {Helmet} from 'react-helmet-async';
import { Link } from 'react-router-dom';
import {format, parse} from 'date-fns';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import SectionTemplate from '../../templates/sectionTemplate/SectionTemplate';
import {Tabs, Tab} from 'react-bootstrap';
import TableLiniersPrices from '../../atoms/Tables/TableLiniersPrices/TableLiniersPrices';
import {useTranslation} from 'react-i18next';
import withSSR from '../../withSSR';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import {splitUrl} from './../../utils/stringUtils';
import {gtmInitialize, manageGTMPageName, manageGTMUserStatus} from './../../utils/GTMDataLayerFuncs';
import NoFilterData from '../../atoms/NoFilterData/NoFilterData';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import UserContext from '../../atoms/UserContext/UserContext';

function PricesCañuelas(props) {
  const serverData = props && props.serverData;
  const pathName = props ? props.location.pathname : false;
  const [updatedData, setUpdatedData] = useState(serverData || null);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const amount = (serverData && serverData.pages);
  const {t} = useTranslation();
  const loader = <div className="loader container p-0"><div className="animated-background" /></div>;

  const templateBanners = [
    {
      adUnit: 'mercado_ganadero_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'mercado_ganadero_middle_2',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'mercado_ganadero_middle_3',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'mercado_ganadero_filmstrip',
      sizes: [[300, 600]]
    }
  ];
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  const { mkpUserData, userLoading } = useContext(UserContext);

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true)
    return getData({url: `${url}/api/News/GetNewsListByCategory?categoryID=67&page=${currentPage - 1}&limit=15&canonical=${pathName}`})
      .then(result => {
        setUpdatedData(result.data)
        setIsLoading(false)
      });
  };

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (updatedData || serverData) {
      const section = document.getElementById('section-template');
      setIsLoading(false);
      if (section && ((serverData !== updatedData) || (currentPage !== previousPage))) {
        setPreviousPage(currentPage);
        fetchData();
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  // Date format 20190219
  const [listLiniers, setListLiniers] = useState();
  const [initialDate, setInitialDate] = useState();
  const [messageLiniers, setMessageLiniers] = useState(false);

  const fetchDataLiniers = url => {
    return getData({
      url: url
    });
  };

  const fetchMarketLiniers = date => {
    setMessageLiniers(false);
    const url = date ?
      `${window.env.RAZZLE_ENDPOINT_COTZ_API}/api/Prices/GetFarmPricesGordo?date=${date}` :
      `${window.env.RAZZLE_ENDPOINT_COTZ_API}/api/Prices/GetFarmPricesGordo`;
    fetchDataLiniers(url).then(resp => {
      setListLiniers(resp.data);
      if (!date) {
        const dates = resp.data.map(x => parse(x.FechaActualizacion, 'dd/MM/yyyy', new Date()));
        const maxDate = new Date(Math.max.apply(null, dates));

        setInitialDate(maxDate);
      }
    }).catch(error => {
      setMessageLiniers(true);
      setListLiniers();
      console.error(error);
    });
  };

  const callback = date => {
    setListLiniers();
    setInitialDate(date);
    fetchMarketLiniers(format(date, 'yyyyMMdd'));
  };

  useEffect(() => {
    if(window && serverData) {
      manageGTMPageName('Precios del Mercado de Cañuelas');
    }
  }, [serverData]);

  const marketLiniers = () => {
    return (
      <React.Fragment>
        <Helmet>
          <title>Precios del Mercado de Cañuelas | Agrofy News</title>
          <meta name="robots" content="index,follow" />
          <link rel="canonical" href="https://news.agrofy.com.ar/mercado-ganadero/mercado-de-cañuelas" />
          <meta name="description" content="Las principales noticias sobre los precios del Mercado de Cañuelas están en Agrofy News! Encontrá todas las informaciones sobre estos mercados acá!" />
        <script type="application/ld+json">{ buildBreadcrumbJson(getBreadcrumbData('[{"Mercado ganadero":"/mercado-ganadero"},{"Precios del Mercado de Cañuelas":"/mercado-ganadero/mercado-de-cañuelas"}]', false))}</script>        
        </Helmet>
        <AgroPushNotification location="Section" />
        <div className="mb-36 market-prices-table">
          { // eslint-disable-next-line no-nested-ternary
            messageLiniers ? null : !listLiniers ? loader :
              <>
              <div className="pages-togler">
                { !initialDate ? loader :
                  <div className="calendar-section pr-15">
                    <label className="market-prices-lbl">{t('choose_date')}:</label>
                    <Calendar parentCallback={callback} initialDate={initialDate}></Calendar>
                  </div>
                }
                <ul className="granos-list">
                  <li className='active'><Link to="/mercado-ganadero/mercado-de-cañuelas">Cañuelas</Link></li>
                  <li><Link to="/mercado-ganadero/mercado-de-rosario">Rosario</Link></li>
                  <li><Link to="/mercado-ganadero/mercado-de-liniers">Liniers</Link></li>
                </ul>
              </div>
              <TableLiniersPrices prices={listLiniers.find(item => item.Mercado === 'Cañuelas')} />
              </>
          }
        </div>
        { messageLiniers ? <NoFilterData/> : null }
      </React.Fragment>);
  };

  useEffect(() => {
    fetchMarketLiniers();
  }, []);

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <div className="market-prices container px-0">
        <section className="dfp-banner container top-banner">
          <AdSlot adUnit={'mercado_ganadero_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
        </section>
        <section id="main-section" className="d-flex flex-wrap mt-20">
          <div className="fluid-container col-lg-9 px-0">
            <section className="breadcrumb-wrapper p-0">
              <Breadcrumb breadcrumbLinks={getBreadcrumbData(serverData && serverData.breadcrumbNav, true)} />
            </section>
            <section className="container">
              <h1>{serverData && serverData.seoTitle}</h1>
              <p>{serverData && serverData.seoText}</p>
              <Tabs className="basic-tabs green" transition={false}>
                <Tab eventKey="market-liniers" title='Precios de los mercados'>
                  {marketLiniers()}
                </Tab>
                {/* Precios Invernada y cría - HASTA QUE ESTE EL SERVICIO DISPONIBLE*/}
                {/* <Tab eventKey="rosario" title='Precios Invernada y cría'>
                  <Invernada />
                </Tab> */}
              </Tabs>
              <SectionTemplate category='Mercado de Liniers' data={updatedData || serverData} bannersData={templateBanners} sectionTitle={t('keep_reading')} loading={isLoading}/>
              <div className="mb-36">
                {currentPage && amount &&
                  <PaginationAgrofy
                    parentCallback={setCurrentPage}
                    currentPage={currentPage}
                    amount={amount}
                  />
                }
              </div>
            </section>
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <AdSlot adUnit={'mercado_ganadero_followme'} sizes={[[260, 600]]} />
            </div>
          </div>
        </section>
      </div>
      <AdhesionBanner adUnit='mercado_ganadero_adhesion' sizes={[[320, 80]]} />
      <PopUpBanner adUnit='mercado_ganadero_fullscreen' sizes={[[800, 600], [320, 480]]} />
    </DFPSlotsProvider>
  );
}

PricesCañuelas.getInitialProps = async ({match, req, res}) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const pathName = match && match.url;
  try {
    const {
      data
    } = await getData({url: `${url}/api/News/GetNewsListByCategory?categoryID=67&page=0&limit=15&canonical=${pathName}`});
    return {serverData: data};
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(PricesCañuelas);
