/* eslint-disable no-undef */
/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect, useContext } from "react";
import "intersection-observer"; // optional polyfill
import Observer from "@researchgate/react-intersection-observer";
import { useTranslation } from "react-i18next";
import { DFPSlotsProvider, DFPManager, AdSlot } from "react-dfp";
import { Helmet } from "react-helmet-async";
import firebase from "firebase/app";
import "firebase/messaging";
import AdhesionBanner from "../../atoms/AdhesionBanner/AdhesionBanner";
import SearchBar from "../../components/searchBar/SearchBar";
import getData from "../../services/GetData";
import TopBreakingNews from "../../atoms/TopBreakingNews/TopBreakingNews";
import TopBreakingNewsVideo from "../../atoms/TopBreakingNews/TopBreakingNewsVideo";
import withSSR from "../../withSSR";
import HomeTemplate from "../../templates/homeTemplate/HomeTemplate";
import CapitalizedReferenceComponent  from "../../utils/Components/CapitalizedReferenceComponent";
import PopUpBanner from "../../atoms/PopUpBanner/PopUpBanner";
import Newsletter from "../../organisms/newsletter/Newsletter";
import NewslettersBR from "../../atoms/NewslettersBR/NewslettersBR";
import { gtmInitialize, manageGTMHome, manageGTMUserStatus } from "./../../utils/GTMDataLayerFuncs";
import { AgroPushNotification } from "../../components/AgroPushNotification/AgroPushNotification";
import UserContext from "../../atoms/UserContext/UserContext";
import { datadogRumInitialize } from '../../utils/datadogRumInitialize';

import * as HomePanels from "../../atoms/HomePanels/HomePanels";
import { 
  homePanelsReferenceAR,
  homePanelsReferenceBR,
  homePanelsFallBackDataAR,
  homePanelsFallBackDataBR 
} from "../../utils/Home/HomePanels";

import "./Home.scss";

const Home = (props) => {
  const { t } = useTranslation();
  const data = props && props.serverData ? props.serverData : false;
  const [showSponsoredBy, setShowSponsoredBy] = useState(false);
  const opinionData = props && props.opinionData;
  const loader = (
    <div className="loader container pr-0">
      <div className="animated-background" />
    </div>
  );
  // const [headerBannerLoaded, setHeaderBannerLoaded] = useState('no-loaded-banner');
  const [middle7Loaded, setMiddle7Loaded] = useState("no-loaded-banner");
  const [loadedComponents, setLoadedComponents] = useState({});
  let observerSections = [
    "Carousel",
    "Video",
    "SectionAgriculture",
    "SectionAgricultureSndRow",
    "CategorySections",
    "ContinueReadingSection",
  ];
  const [sections, setSections] = useState([]);
  const [middle5Loaded, setMiddle5Loaded] = useState("no-loaded-banner");
  const [middle6Loaded, setMiddle6Loaded] = useState("no-loaded-banner-mobile");
  const [fairData, setFairData] = useState(null);
  const newsletterSubTitle = t("subscribe_newsletter");
  const newsletterTitle = t("find_out_news");
  const showAgrofyTV = process.env.SHOW_AGROFYTV || typeof window !== 'undefined' && window.env.SHOW_AGROFYTV;
  const bannersReload = process.env.DFP_RELOAD || typeof window !== 'undefined' && window.env.DFP_RELOAD;

  const breakingNews = {
    top: props && props.serverData && props.serverData.superImportantNews1,
    main: props && props.serverData && props.serverData.superImportantNews2,
    stream: props && props.serverData && props.serverData.stream,
  };

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const dfpNetworkID = process.env.DFP_NETWORK_ID || window.env.DFP_NETWORK_ID;

  const firebaseConfig = {
    apiKey: "AIzaSyDFXnk6OFeUlYOosTdTd-fYGVMbH9UiOmo",
    authDomain: "agrofynews-push-notifications.firebaseapp.com",
    projectId: "agrofynews-push-notifications",
    storageBucket: "agrofynews-push-notifications.appspot.com",
    messagingSenderId: "393282723522",
    appId: "1:393282723522:web:309088002fe321ea4d255b",
  };

  const { mkpUserData, userLoading } = useContext(UserContext);

  const weatherDefaultCityId = isBrasil ? '43348' : '11222';
  const [weatherSavedCityId, setWeatherSavedCityId] = useState(false);
  const [weatherData, setWeatherData] = useState();

  useEffect(() => {
    try {
      setWeatherSavedCityId(JSON.parse(localStorage.getItem('savedCityId')) || weatherDefaultCityId);

      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    if (window && data) {
      manageGTMHome(data && data.momentTopics.map((x) => x.title));
    }
  }, [data]);

  // Refresh page after 10 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    try {
      if (firebase.messaging.isSupported()) {
        setMessaging(firebase.messaging());
      }
    } catch (err) {}
  }, []);

  useEffect(() => {
    const fetchData = () => {
      const url = `${window.env.RAZZLE_ENDPOINT_NOTI_API}/api/News/GetSectionHome?size1=4&size2=3&size3=3`;
      getData({ url }).then((resp) => {
        setSections(resp.data);
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchWeatherData = () => {
      if (weatherSavedCityId) {
        const url =
          process.env.RAZZLE_ENDPOINT_NEWS_API ||
          window.env.RAZZLE_ENDPOINT_NEWS_API;
        getData({
          url: `${url}/api/Weather/GetFiveDays?idCity=${weatherSavedCityId}`,
        }).then((result) => {
          setWeatherData(result.data.DailyForecasts);
        });
      }
    };

    fetchWeatherData();
  }, [weatherSavedCityId]);

  const addView = async (componentName) => {
    let loadedComponent = {};

    import(`../../components/${componentName}/${componentName}`).then(
      (Component) => {
        loadedComponent[componentName] = (
          <Component.default
            isHome={true}
            data={sections}
            fairDesc={fairDesc}
          />
        );
        setLoadedComponents({ ...loadedComponents, ...loadedComponent });
      }
    );

    if (componentName == "ContinueReadingSection") {
      observerSections.forEach((element) => {
        import(`../../components/${element}/${element}`).then((Component) => {
          loadedComponent[element] = (
            <Component.default
              isHome={true}
              data={sections}
              fairDesc={fairDesc}
            />
          );
          setLoadedComponents({ ...loadedComponents, ...loadedComponent });
        });
      });
    }
  };

  const HomePanel = ({position}) => {
    let componentType;

    const panelsData = data && data.homePanels;
    const fallBackData = isBrasil ? homePanelsFallBackDataBR : homePanelsFallBackDataAR;
    const homeBoardPositions = panelsData ?? fallBackData;

    const reference = isBrasil ? homePanelsReferenceBR : homePanelsReferenceAR;

    try {
      componentType = reference.find(x => x.codeName === homeBoardPositions.find(x => x.delta === position).name).type;
    }
    catch (err) {
      componentType = reference.find(x => x.codeName === fallBackData.find(x => x.delta === position).name).type
    }

    return componentType && 
    <CapitalizedReferenceComponent
      components={HomePanels}
      type={componentType}
      dollarData={data && data.dollar}
      DollarShowSponsoredBy={true}
      quotesData={data && data.pricesNew}
      quotesShowSponsoredBy={true}
      agriculturaShowSponsoredBy={true}
      pecuariaShowSponsoredBy={true}
      weatherData={weatherData}
      weatherShowSponsoredBy={true}
      weatherShowSponsoredByBR={true}
      weatherDaysToDisplay={position == 2 ? 5 : 3}
    />;
  };

  const handleChange = async (event) => {
    const componentName = event.target.dataset.name;
    if (event.isIntersecting) {
      addView(componentName);
    }
  };
  const options = {
    onChange: handleChange,
    rootMargin: "150px 0px 150px 0px",
  };

  const fairDesc = (fair) => {
    setFairData(fair);
  };

  const carouselUrl = isBrasil
    ? "https://www.agrofy.com.br"
    : "https://www.agrofy.com.ar";


  //Banners refresh
  useEffect(() => {
    if(bannersReload === 'true') {
      var interval = window.setInterval(      
        () => { 
          DFPManager.refresh();
        },
        60000,
      );
      return () => {
        window.clearInterval(interval);
      };
    }
  }, []);

  const loadBanner = (idBanner) => {
    switch(idBanner){
      case 'home_middle_7':
        setMiddle7Loaded('loaded-banner');
        break;
      case 'home_middle_6':
        setMiddle6Loaded("loaded-banner-mobile");
        break;
      case 'home_middle_5':
        setMiddle5Loaded("loaded-banner");
        break;
    }
  }

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID} class="Home">
      <div className="Home">
        <Helmet>
          <title>
            {isBrasil
              ? "Agrofy News | As melhores notícias agropecuárias estão aqui"
              : "Agrofy News | Todas las noticias agropecuarias en un solo lugar"}
          </title>
          <link
            rel="canonical"
            href={
              isBrasil
                ? "https://news.agrofy.com.br"
                : "https://news.agrofy.com.ar"
            }
          />
          <meta
            name="description"
            content={
              isBrasil
                ? "Tudo o que você precisa saber sobre o setor agropecuário vai encontrar na Agrofy News! As principais notícias sobre o campo brasileiro e região estão aqui!"
                : "Encontrá toda la actualidad y noticias del campo en Agrofy News. El pronostico del tiempo de las principales ciudades del sector, cotizaciones de granos y más!"
            }
          />
          <meta name="robots" content="index,follow" />
          <link
            rel="preload"
            href="../../components/Carousel/Carousel.js"
            as="fetch"
          />
          <link
            rel="preload"
            href="../../components/SectionAgriculture/SectionAgriculture.js"
            as="fetch"
          />
          <link
            rel="preload"
            href="../../components/SectionAgricultureSndRow/SectionAgricultureSndRow.js"
            as="fetch"
          />
          <link
            rel="preload"
            href="../../components/CategorySections/CategorySections.js"
            as="fetch"
          />
          <link rel="preload" href="../../components/Video/Video.js" as="fetch" />
          <link
            rel="preload"
            href="../../components/ContinueReadingSection/ContinueReadingSection.js"
            as="fetch"
          />
          <meta
            name="facebook-domain-verification"
            content="mlf8zbdda586vlnd6c2s3wg6r29je3"
          />
          <link
            href="https://news.agrofy.com.ar"
            hrefLang="es-ar"
            rel="alternate"
          />
          <link
            href="https://news.agrofy.com.br"
            hrefLang="pt-br"
            rel="alternate"
          />
        </Helmet>
        <AgroPushNotification location="Home" />
        {/*<MomentTopics topics={data && data.momentTopics} />*/}
        <section className="dfp-banner stripped px-0 container top-banner">
          <AdSlot
            adUnit={`home_header`}
            sizes={[[1200, 90], [970, 90], [970, 250], [320, 80]]}
            onSlotRender={(e) => e.event.isEmpty ? "" : loadBanner("home_header")}
          />
        </section>
        {data?.promotedVideo &&
        data?.promotedVideo !== null &&
        typeof data?.promotedVideo !== "undefined" ? (
          <TopBreakingNewsVideo data={data?.promotedVideo} />
        ) : (
          (breakingNews.top || breakingNews.stream) && (
            <TopBreakingNews data={breakingNews.top || breakingNews.stream} />
          )
        )}
        {
          <section id="cotization-section" className="container mt-20 py-0">
            <div className="d-flex flex-wrap panels">
              <div className="h-scroll col-lg-8 d-flex p-0">
                <div className="col-11 col-sm-8 col-md-6 col-lg-6 pl-0 flex-shrink-0 first-panel">
                  {!data ? loader : <HomePanel position={0} />}
                </div>
                <div className="col-12 col-sm-7 col-md-6 col-lg-6 pr-0 pl-lg-0 pl-0 flex-shrink-0">
                  {!data ? loader : <HomePanel position={1} />}
                </div>
              </div>
              <div className="col-lg-4 mt-m-20 pl-m-0 pr-0 daily-container">
                {!data ? loader : <HomePanel position={2} />}
              </div>
            </div>
            <div></div>
          </section>
        }
        <section id="main-section d-flex" className="container py-0 mt-20">
          <div className="col-lg-9 px-0">
            {!data ? (
              loader
            ) : (
              <HomeTemplate
                leadNews={data.mainNews}
                breakingNews={breakingNews.main || null}
                opinionData={opinionData}
              />
            )}
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner d-none d-lg-block stripped">
            <div className="make-me-sticky stripped">
              <AdSlot
                adUnit={`home_followme`}
                sizes={[[260, 600]]}
                onSlotRender={(e) => e.event.isEmpty ? "" : loadBanner("home_followme")}
              />
            </div>
          </div>
        </section>
        <section
          id="marketplace-carousel"
          className="container my-68 my-m-36 pb-0"
        >
          <div className="wrapper">
            <div className="search-wrapper d-flex flex-wrap">
              <div className="col-sm-3 col-xl-4 left-content">
                <a href={carouselUrl} target="_blank">
                  <i className="agrofy-logo"></i>
                </a>
                <label className="carousel-header-text d-none d-xl-flex">
                  {t("find_everything_for_your_field")}
                </label>
              </div>
              <div className="col-sm-9 col-md-6 col-xl-4">
                <SearchBar />
              </div>
            </div>
            <div className="carousel-wrapper">
              {fairData !== null ? (
                <div className="fair-head">
                  <span className="fair-head-title">
                    {fairData && fairData.Title}
                  </span>
                  <span className="fair-head-link">
                    <a href={`${carouselUrl}/${fairData && fairData.Link}`}>
                      Ver ofertas
                    </a>
                  </span>
                  <div className="fair-head-claim">
                    <span className="fair-head-claim-text">
                      {fairData && fairData.Claim}
                    </span>
                  </div>
                </div>
              ) : null}
              <Observer {...options}>
                <div data-name="Carousel" className="observer-carrousel">
                  {loadedComponents["Carousel"]}
                </div>
              </Observer>
            </div>
          </div>
        </section>
        <div className={`mb-68 container ${middle7Loaded}`}>
          <div className="dfp-banner stripped bm-negative-ml-15">
            <AdSlot
              adUnit={`home_middle_7`}
              sizes={[[728, 90], [300, 250], [320, 50]]}
              onSlotRender={(e) => e.event.isEmpty ? "" : loadBanner("home_middle_7")}
            />
          </div>
        </div>
        { showAgrofyTV === 'true' ? (
          <section id="video-section" className="fluid-container">
            <Observer {...options}>
              <div data-name="Video" className="observer-video">
                {loadedComponents["Video"]}
              </div>
            </Observer>
          </section>
        ) : null}
        <section
          id="sections"
          className="d-flex flex-wrap container py-0 mt-36 category-section-container"
        >
          <div className="col-lg-9">
            <div className="row">
              <div className="col-12 p-0">
                <Observer {...options}>
                  <div
                    data-name="SectionAgriculture"
                    className="observer-section-agriculture"
                  >
                    {loadedComponents["SectionAgriculture"]}
                  </div>
                </Observer>
                <div className="col d-flex flex-wrap px-m-0 p-0 second-row">
                  <div className="col-12 col-lg-6 col-xl-5 mb-m-36 px-0">
                    <div className={`m-full-width ${middle6Loaded}`}>
                      <div className="dfp-banner stripped">
                        <AdSlot
                          adUnit={`home_middle_6`}
                          sizes={[[300, 250], [320, 50]]}
                          onSlotRender={(e) => e.event.isEmpty ? "" : loadBanner('home_middle_6')}
                        />
                      </div>
                    </div>
                  </div>
                  <Observer {...options}>
                    <div
                      data-name="SectionAgricultureSndRow"
                      className="observer-section-agriculture-snd col d-flex flex-wrap"
                    >
                      {loadedComponents["SectionAgricultureSndRow"]}
                    </div>
                  </Observer>
                  <div id="newsletter" className="col p-0 mb-68 my-m-36">
                    {countryName !== "Brasil" ? (
                      <Newsletter
                        isFull={true}
                        title={newsletterTitle}
                        subTitle={newsletterSubTitle}
                        placeholder="Ingresá tu e-mail"
                        buttonText="Suscribirse"
                      ></Newsletter>
                    ) : (
                      <NewslettersBR />
                    )}
                  </div>
                </div>
              </div>
              <div className={`mb-35 container px-0 ${middle5Loaded}`}>
                <div className="dfp-banner stripped bm-negative-ml-15">
                  <AdSlot
                    adUnit={`home_middle_5`}
                    sizes={[[728, 90], [300, 250], [320, 50]]}
                    onSlotRender={(e) => e.event.isEmpty ? "" : loadBanner('home_middle_5')}
                  />
                </div>
              </div>
              <Observer {...options}>
                <div
                  data-name="CategorySections"
                  className="observer-category-sections"
                >
                  {loadedComponents["CategorySections"]}
                </div>
              </Observer>
            </div>
          </div>
          <div className="col-lg-3 row sidebar-item px-0 dfp-banner stripped d-none d-lg-block mt-45">
            <div className="make-me-sticky">
              <AdSlot
                adUnit={`home_followme_2`}
                sizes={[[260, 600]]}
                onSlotRender={(e) => e.event.isEmpty ? "" : loadBanner('home_followme_2')}
              />
            </div>
          </div>
        </section>
        {countryName !== "Brasil" ? (
          <Observer {...options}>
            <div
              data-name="ContinueReadingSection"
              className="observer-keepreading"
            >
              {loadedComponents["ContinueReadingSection"]}
            </div>
          </Observer>
        ) : null}
        <AdhesionBanner
          adUnit={`home_adhesion`}
          sizes={[[320, 80]]}
        />
        <PopUpBanner
          adUnit={`home_fullscreen`}
          sizes={[
            [800, 600],
            [320, 480],
          ]}
        />
      </div>
    </DFPSlotsProvider>
  );
};

Home.getInitialProps = async ({ match, req, res }) => {
  const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
  const urlNoticia = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  try {
    const [opinion, server] = await Promise.all([
      getData({ url: `${urlNoticia}/api/Opinion/GetHomeOpinions?cont=4` }),
      getData({ url: `${url}/api/FirstData/Home` }),
    ]);
    return {
      opinionData: opinion.data,
      serverData: server.data,
    };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(Home);
