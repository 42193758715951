/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect, useContext } from 'react';
import { DFPSlotsProvider, DFPManager, AdSlot } from 'react-dfp';
import Newsletter from '../../organisms/newsletter/Newsletter';
import NewslettersBR from '../../atoms/NewslettersBR/NewslettersBR';
// import PropTypes from 'prop-types';
import StandarCard, {
  LoaderCard,
} from '../../components/standarCard/StandarCard';
import { useTranslation } from 'react-i18next';
import getData from '../../services/GetData';
import VideoCard from '../../atoms/VideoCard/VideoCard';
import AgrofyTVVideoCard from '../../atoms/AgrofyTVVideoCard/AgrofyTVVideoCard';
import TableDollarAndStocks from '../../atoms/Tables/TableDollarAndStocks/TableDollarAndStocks';
import SearchBar from '../../components/searchBar/SearchBar';
import Carousel from '../../components/Carousel/Carousel';
import MainCardLive from '../../molecules/MainCardLive/MainCardLive';
import DarkModeContext from '../../atoms/DarkModeContext/DarkModeContext';
import './SectionTemplate.scss';
// import TagManager from 'react-gtm-module';

const SectionTemplate = ({
  data,
  hasVideo = false,
  isExpo = false,
  hasAgrofyTVVideo = false,
  agrofyTVPlaylist,
  category,
  classes,
  sectionTitle,
  bannersData,
  hideBannerTop = false,
  videoCategory,
  loading,
  isTagPage,
  showDolarTable,
  showNewslettersForm = true,
  hasCarousel = true,
  carouselKeyword = '',
  carouselId = '',
  carouselTab = '',
  hasVideoCarousel = false
}) => {
  const { t } = useTranslation();
  const news = data && data.news;
  const videos = data && data.videos;
  const newsletterSubTitle = t('subscribe_newsletter');
  const newsletterTitle = t('find_out_news');
  const [middle1Loaded, setMiddle1Loaded] = useState('no-loaded-banner-mobile');
  const [middle2Loaded, setMiddle2Loaded] = useState('no-loaded-banner-mobile');
  const [fairData, setFairData] = useState(null);
  const [video, setVideo] = useState();
  const { isDarkMode, toggleMode } = useContext(DarkModeContext);
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === 'Brasil';
  const bannersReload = process.env.DFP_RELOAD || typeof window !== 'undefined' && window.env.DFP_RELOAD;
  
  let dfpNetworkID =
    process.env.DFP_NETWORK_ID ||
    (typeof window !== 'undefined' && window.env.DFP_NETWORK_ID);

  dfpNetworkID =
    dfpNetworkID && isExpo
      ? dfpNetworkID.replace(dfpNetworkID.substring(dfpNetworkID.indexOf('/') + 1), 'agrofynews_especificos')
      : dfpNetworkID;

  const fetchData = () => {
    getData({
      url: `${window.env.RAZZLE_ENDPOINT_NOTI_API}/api/News/GetMultimedia/${videoCategory}`,
    }).then((resp) => setVideo(resp.data));
  };

  useEffect(() => {
    hasVideo && fetchData();
  }, []);

  const standardCard = (value, isMini) => {
    return isMini ? (
      <div className="mb-35">
        <StandarCard
          clampText={5}
          miniStandarCard
          link={value && value.url}
          image={value && value.image}
          category={value && value.category}
          imageTitle={value.title}
          body={value.title}
          loading={!news || !Array.isArray(news) || loading}
          isDarkMode={isDarkMode}
          toggleMode={toggleMode}
          exclusive={value && value.exclusive}
        ></StandarCard>
      </div>
    ) : (
      <div className={`mb-35 ${isSingleCard && 'single-card'}`}>
        <StandarCard
          clampText={4}
          link={value && value.url}
          image={value && value.image}
          category={value && value.category}
          imageTitle={value && value.title}
          body={value && value.title}
          loading={!news || !Array.isArray(news) || loading}
          isDarkMode={isDarkMode}
          toggleMode={toggleMode}
          exclusive={value && value.exclusive}
        ></StandarCard>
      </div>
    );
  };
  const TitleLoader = ({ style }) => (
    <div className="loader pr-0 animated-background" style={{ ...style }}></div>
  );
  const isSingleCard = news && news.length === 1;

  const fairDesc = (fair) => {
    setFairData(fair);
  };

  const mkpUrl = isBrasil
    ? 'https://www.agrofy.com.br'
    : 'https://www.agrofy.com.ar';

  //Banners refresh
  useEffect(() => {
    if(bannersReload === 'true') {
      var interval = window.setInterval(
        () => { 
          DFPManager.refresh(); 
        },
        60000,
      );
      return () => {
        window.clearInterval(interval);
      };
    }
  }, []);

  return (
    <section
      id="section-template"
      className={`d-flex flex-wrap mb-36 ${classes ? classes : ''}`}
    >
      <div className="container d-flex flex-wrap p-0 pb-35">
        {isTagPage && showDolarTable && (
          <div className="economic-indicators-content tags-page">
            <TableDollarAndStocks isTagPage showStocks={false} />
          </div>
        )}        
        <div className="row keep-reading">
          <div className="col-lg-4">
            {sectionTitle ? (
              loading ? (
                <TitleLoader
                  style={{ minHeight: '34px', marginTop: '12px', width: '50%' }}
                />
              ) : (
                <h2 className="mb-20 mt-10">{sectionTitle}</h2>
              )
            ) : null}
            {!loading && news && news[0] && typeof news[0] !== 'undefined' ? (
              standardCard(news[0], false)
            ) : loading ? (
              <div className="mb-35">
                <LoaderCard />
              </div>
            ) : null}
          </div>
          {/* {!(!news || !Array.isArray(news) || loading) &&
            <> */}
          <div className="col-lg-4">
            {!loading && news && news[1] && typeof news[1] !== 'undefined' ? (
              standardCard(news[1], false)
            ) : loading ? (
              <div className="mb-35">
                <LoaderCard />
              </div>
            ) : null}
          </div>
          <div className="col-lg-4">
            {
              // eslint-disable-next-line no-nested-ternary
              hasVideo && videoCategory && !loading && !isBrasil ? (
                <VideoCard video={video} />
              ) : hasAgrofyTVVideo &&
                agrofyTVPlaylist &&
                !loading &&
                !isBrasil ? (
                <AgrofyTVVideoCard playlistID={agrofyTVPlaylist} />
              ) : news &&
                news[2] &&
                typeof news[2] !== 'undefined' &&
                !loading ? (
                standardCard(news[2], false)
              ) : loading ? (
                <div className="mb-35">
                  <LoaderCard />
                </div>
              ) : null
            }
          </div>
          {/* </>
          } */}
        </div>
        {!hideBannerTop ? (<div className={`section-template__banner col-12 m-full-width px-0 `}>
          <div className="dfp-banner stripped">
            {!bannersData ? (
              <div className="banner b-728-90" />
            ) : (
              <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                <AdSlot
                  adUnit={`${bannersData[0].adUnit}`}
                  sizes={bannersData[0].sizes}
                  onSlotRender={(e) =>
                    e.event.isEmpty ? '' : setbanner0Loaded('loaded-banner')
                  }
                />
              </DFPSlotsProvider>
            )}
          </div>
        </div>) : ''}
        {hasVideoCarousel ? <MainCardLive isCategoryPage={true} withCarousel videoData={videos} /> : null}
        <div className="col d-flex flex-wrap mb-36 px-0">
          <div className="col-5 d-none d-lg-block px-0">
            <div className="dfp-banner stripped">
              {!bannersData ? (
                <div className="banner b-300-600" />
              ) : (
                <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                  <AdSlot
                    adUnit={`${bannersData[3].adUnit}`}
                    sizes={bannersData[3].sizes}
                  />
                </DFPSlotsProvider>
              )}
            </div>
          </div>
          <div className="col-lg-7 d-flex flex-wrap px-m-0 pr-lg-0">
            {
              <div className="d-flex p-0 row">
                {!(!news || !Array.isArray(news) || loading)
                  ? news.slice(3, 7).map((value, index) => (
                      <div className="col-lg-6" key={index}>
                        {standardCard(value, true)}
                      </div>
                    ))
                  : loading
                  ? ['news4', 'news5', 'news6', 'news7'].map((val, ind) => (
                      <div className="col-lg-6" key={ind}>
                        <div className="mb-35">
                          <LoaderCard miniStandarCard />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            }
          </div>
        </div>
        {!hasCarousel || isTagPage || 
        (category && category.toLowerCase === 'granos') ||
        (category && category.toLowerCase === 'clima') ? null : (
          <section id="marketplace-carousel" className="container mb-36 pb-0">
            <div className="wrapper">
              <div className="search-wrapper d-flex flex-wrap">
                <div className="col-sm-3 col-xl-6 left-content">
                  <a href={mkpUrl} target="_blank">
                    <i className="agrofy-logo"></i>
                  </a>
                  <label className="carousel-header-text d-none d-xl-flex">
                    {t('find_everything_for_your_field')}
                  </label>
                </div>
                <div className="col-sm-9 col-md-6 col-xl-6">
                  <SearchBar />
                </div>
              </div>
              <div className={`carousel-wrapper`}>
                {fairData !== null ? (
                  <div className="fair-head">
                    <span className="fair-head-title">
                      {fairData && fairData.Title}
                    </span>
                    <span className="fair-head-link">
                      <a href={`${mkpUrl}/${fairData && fairData.Link}`}>
                        Ver ofertas
                      </a>
                    </span>
                    <div className="fair-head-claim">
                      <span className="fair-head-claim-text">
                        {fairData && fairData.Claim}
                      </span>
                    </div>
                  </div>
                ) : null}
                <Carousel
                  searchStr={carouselKeyword ?? ''}
                  agroshopId={carouselId ?? ''}
                  agroshopTab={carouselTab ?? ''}
                  fairDesc={fairDesc}
                  isCategory
                />
              </div>
            </div>
          </section>
        )}        
        <div className="d-flex flex-wrap p-0">
          {!(!news || !Array.isArray(news) || loading)
            ? news.slice(7, 10).map((value, index) => (
                <div className="col-lg-4 px-lg-15 p-sm-0" key={index}>
                  {standardCard(value, true)}
                </div>
              ))
            : loading
            ? ['news8', 'news9', 'news10'].map((val, ind) => (
                <div className="col-lg-4" key={ind}>
                  <div className="mb-35">
                    <LoaderCard miniStandarCard />
                  </div>
                </div>
              ))
            : null}
        </div>
        <div className="d-flex p-0 row m-lg-0">
          <div className="col-lg-4 px-lg-15">
            {!loading && news && news[10] && typeof news[10] !== 'undefined' ? (
              standardCard(news[10], true)
            ) : loading ? (
              <LoaderCard miniStandarCard />
            ) : null}
          </div>
          <div className={`col-lg-8 mx-0 mb-35 m-full-width ${middle1Loaded}`}>
            <div className="dfp-banner stripped">
              {!bannersData ? (
                <div className="banner b-300-250" />
              ) : (
                <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                  <AdSlot
                    adUnit={`${bannersData[1].adUnit}`}
                    sizes={bannersData[1].sizes}
                    onSlotRender={(e) =>
                      e.event.isEmpty
                        ? ''
                        : setMiddle1Loaded('loaded-banner-mobile')
                    }
                  />
                </DFPSlotsProvider>
              )}
            </div>
          </div>
        </div>
        <div
          id="newsletter"
          className="col mb-35 p-0"
          style={{ minWidth: '100%' }}
        >
          {isBrasil ? (
            <NewslettersBR />
          ) : showNewslettersForm ? (
            <Newsletter
              isFull={true}
              title={newsletterTitle}
              subTitle={newsletterSubTitle}
              placeholder="Ingresá tu e-mail"
              buttonText="Suscribirse"
            ></Newsletter>
          ) : null}
        </div>
        <div className="middle-banner p-0 w-100">
          <div className={`col-lg-8 mb-35 m-full-width ${middle2Loaded}`}>
            <div className="dfp-banner stripped">
              {!bannersData ? (
                <div className="banner b-300-250" />
              ) : (
                <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                  <AdSlot
                    adUnit={`${bannersData[2].adUnit}`}
                    sizes={bannersData[2].sizes}
                    onSlotRender={(e) =>
                      e.event.isEmpty
                        ? ''
                        : setMiddle2Loaded('loaded-banner-mobile')
                    }
                  />
                </DFPSlotsProvider>
              )}
            </div>
          </div>
          <div className="col-lg-4 single">
            {!loading && news && news[11] && typeof news[11] !== 'undefined' ? (
              standardCard(news[11], true)
            ) : loading ? (
              <LoaderCard miniStandarCard />
            ) : null}
          </div>
        </div>
        <div className="d-flex flex-wrap p-0">
          {!loading && news
            ? news.slice(12, 15).map((value, index) => (
                <div className="col-lg-4 px-lg-15 p-sm-0" key={index}>
                  {standardCard(value, true)}
                </div>
              ))
            : loading
            ? ['news13', 'news14', 'news15'].map((val, ind) => (
                <div className="col-lg-4 px-lg-15 p-sm-0" key={ind}>
                  <div className="mb-35">
                    <LoaderCard miniStandarCard />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </section>
  );
};

SectionTemplate.defaultProps = {
  data: Array(15)
    .fill()
    .map((value) => {
      return {
        miniStandarCard: '',
        littleTex: '',
        link: 'https://news.agrofy.com.ar/noticia/180965/campo-cristina-es-jugada-electoral-que-no-cambia-nada',
        image:
          'https://argentina.agrofystatic.com/media/static/listing/logo-placeholder.jpg',
        imageTitle: 'Agrofy',
        category: 'Granos',
        body: 'Agrofy',
      };
    }),
};

export default SectionTemplate;
