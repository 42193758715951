import React, { useState, useEffect } from 'react';
import getData from '../../services/GetData';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';
import { AgroCard, AgroButton, AgroLabel } from 'agrofy-ui-components';
import './Carousel.scss';

const Carousel = ({ searchStr, agroshopId, agroshopTab, fairDesc, isCategory }) => {
  const [isClient, setIsClient] = useState(false);
  const [carouselProducts, setCarouselProducts] = useState();
  const [fairDescription, setFairDescription] = useState();

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";
  
  const { t } = useTranslation();
  
  const CONSULT = t('ask_price');
  const BUY = t('buy_act');
  const QUOTE = t('quote');

  const getMessage = (item) => {

    const price = item.price;
    const isQuotable = item.IsQuotable;
    const isCheckoutForm = item.IsCheckoutForm;
    const stockQuantity = item.StockQuantity;

    if (
      (price === 0 && !isQuotable && !isCheckoutForm) ||
      (price === 0 && !isQuotable && isCheckoutForm) ||
      (price !== 0 && !isQuotable && !isCheckoutForm) ||
      (((price !== 0 && isCheckoutForm) || isQuotable) && stockQuantity <= 0)
    ) {
      return CONSULT;
    }
    if (
      ((price == 0 && isQuotable && !isCheckoutForm) ||
        (price != 0 && isQuotable && !isCheckoutForm)) &&
      !(stockQuantity <= 0)
    ) {
      return QUOTE;
    }
    if (
      ((price == 0 && isQuotable && isCheckoutForm) ||
        (price != 0 && !isQuotable && isCheckoutForm) ||
        (price != 0 && isQuotable && isCheckoutForm)) &&
      !(stockQuantity <= 0)
    ) {
      return BUY;
    }
  }

  const manageGTMImpression = products => {
    const items = products.map((product, pos) => {
      return {
        id: product.Id,
        name: product.name,
        brand: product.marcas_dropdown,
        price: product.usdPrice,
        category: `${product.main_category_name}/${product.submain_category_name}/${product.categoryname}/${product.condicion}/${product.finalidad}`,
        variant: `${product.agroshopname}-${product.membership_name}`,
        position: pos + 1,
        list: 'Agrofy News',
        dimension2: product.id_cliente
      };
    });
    const tagManagerArgs = {
      dataLayer: {
        event: 'productImpression',
        ecommerce: {
          currencyCode: 'USD',
          impressions: items
        }
      }
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  const manageGTMClick = (item, position) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: { list: 'Agrofy News' },
            products: [{
              id: item.Id,
              name: item.name,
              brand: item.marcas_dropdown,
              price: item.usdPrice,
              category: `${item.main_category_name}/${item.submain_category_name}/${item.categoryname}/${item.condicion}/${item.finalidad}`,
              variant: `${item.agroshopname}-${item.membership_name}`,
              position: position + 1,
              dimension2: item.id_cliente
            }]
          }
        }
      }
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  const transformToUrlStr = str => str.split(' ').join('%20');

  var clampedText = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'break-spaces'
  };

  useEffect(() => {
    setIsClient(true)
    const fetchData = () => {
      var service = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
      
      if(agroshopId && agroshopTab) {
        service += `/api/News/GetListingCarouselAgroshop?id=${agroshopId}&tab=${agroshopTab}`;
      } else {
        const searchStrUrl = searchStr ? transformToUrlStr(searchStr) : "";
        service += `/api/News/GetListingCarousel?keyword=${searchStrUrl}`;
      }

      getData({ url: service })
        .then(resp => {
          setCarouselProducts(resp && resp.data && resp.data.hits);
          setFairDescription(resp && resp.data && resp.data.fair);
          if (resp && resp.data && resp.data.hits) {
            manageGTMImpression(resp.data.hits);
          }
        });
    };
    fetchData();
  }, []);

  const formatPrice = price => {
    return Number(price).toLocaleString("de-DE", {minimumFractionDigits: 2}).replace(",00", "");
  }

  const loader = <div className="loader container px-0"><div className="animated-background" /></div>;
  const url = isBrasil ? "https://www.agrofy.com.br" : "https://www.agrofy.com.ar";
  const getcarousel = () => {
    return !(carouselProducts) ? loader :
      (
        carouselProducts.map((item, pos) =>
          <div className="agrocard-container" key={item.Id}>
            <a href={`${url}${item.url_key}`} onClick={() => manageGTMClick(item, pos)} target="_blank" className="product">
              <AgroCard src={item.image} className="agrocard-carousel">
                {fairDescription && fairDescription.FestivalName ?
                  (fairDescription.FestivalName.toLowerCase() === 'agroweek' ?
                  <CustomAgroLabel className="fair-lbl" isAgroweek>
                    {fairDescription.FestivalName}
                  </CustomAgroLabel>
                  :
                  <AgroLabel className="fair-lbl">
                    {fairDescription.FestivalName}
                  </AgroLabel>
                  )
                : null}
                <div className="product-content">
                  <div className="content">
                    <div className="condition">
                      <label className="condition-lbl">
                        {item.condicion ? item.condicion : '\u00A0'}
                      </label>
                    </div>
                    <div className="product-descr-cont">
                      <p className="product-name" style={clampedText}>{item.name}</p>
                      <p className="product-loc" style={clampedText}>{item.aviso_ciudad}</p>
                    </div>
                    <div className="price">
                      <div>
                        <div>
                          {item.special_price ?
                          <div className="old-price">
                            <label className="currency">
                              <s>{item.price && item.moneda ? item.moneda : null}</s>
                            </label>
                            <label className="value">
                              <s>{item.price ? formatPrice(item.price) : null}</s>
                            </label>
                            {item.price && item.porcentaje_descuento_oferta ? item.porcentaje_descuento_oferta &&
                              <span className="discount"> &nbsp; {item.porcentaje_descuento_oferta}% OFF</span>
                              : '\u00A0'
                            }
                          </div>
                          :
                          <div className="old-price">
                            {'\u00A0'}
                          </div>}
                          <div className="current-price">
                            {
                              item.price === 0 && (item.IsQuotable || item.IsCheckoutForm) ? 
                              (<label className="no-value">{t('get_it_now')}</label>) : 
                              item.price === 0 && !item.IsQuotable && !item.IsCheckoutForm ?
                              (<label className="no-value">{t('get_advice')}</label>) :
                              <>
                                <label className="currency">{item.moneda}&nbsp;</label>
                                {item.special_price ? 
                                  <label className="value">{formatPrice(item.special_price)}</label> : 
                                  <label className="value">{formatPrice(item.price)}</label>
                                }
                              </>
                            }
                          </div>
                          <div className="offer-cont">
                            {item.aviso_condicion_1 !== null ?
                              <label className="offer" style={clampedText}>
                                {item.aviso_condicion_1}
                              </label>
                              : '\u00A0'}
                            {item.aviso_condicion_2 !== null ?
                              <label className="offer" style={clampedText}>
                                {item.aviso_condicion_2}
                              </label>
                              : '\u00A0'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <AgroButton
                      variant={getMessage(item) === CONSULT ? 'secondary' : 'primary' }
                      fullWidth
                      className="card-button">
                        {getMessage(item)}
                    </AgroButton>
                  </div>
                </div>
              </AgroCard>
            </a>
          </div>
        )
      );
  };

  const responsiveArr = [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        swipeToSlide: true
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        variableWidth: true
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        variableWidth: true
      }
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: true
      }
    }
  ]
  // Carousel settings
  const settings = {
    infinite: true,
    speed: 600,
    easing: 'linear',
    slidesToShow: isCategory ? 3 : 4,
    slidesToScroll: isCategory ? 3 : 4,
    autoplay: false,
    swipeToSlide: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    variableWidth: false,
    responsive: isClient ? responsiveArr : null
  };

  return (
    <Slider {...settings} className="container" key={isClient ? 'client' : 'server'} onLoad={fairDesc(fairDescription)}>
      {getcarousel()}
    </Slider>
  );
};

export default Carousel;

const CustomAgroLabel = styled(AgroLabel)`
  background-color: ${p => p.isAgroweek && "#DB9931"}; // AgroLabel custom variant not working
`;