import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import { Row, Column, Container, Box } from 'agrofy-ui-components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import StreamingCard from '../../atoms/StreamingCard/StreamingCard';
import SearchBar from '../../components/searchBar/SearchBar';
import Carousel from '../../components/Carousel/Carousel';
import FbComments from '../../components/fbComments/FbComments2';
import NewsletterCardWithInput from '../../atoms/NewsletterCardWithInput/NewsletterCardWithInput';
import MostReadedNew from '../../atoms/MostReadedNew/MostReadedNew';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import RelatedSection from '../../components/RelatedSection/RelatedSection';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import withSSR from '../../withSSR';
import getData from '../../services/GetData';
import { LogoSection, ContainerLogo } from '../AgrofyTv/AgrofyTv';
import { Circle } from '../../molecules/MainVideo/MainVideo';
import Logo from '../../images/icons/agrofytvLogo.svg';
import { structuredVideoData } from '../../utils/StructuredVideoData';
import {gtmInitialize, manageGTMPageName, manageGTMUserStatus} from '../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import './Streaming.scss';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import UserContext from '../../atoms/UserContext/UserContext';

const Streaming = props => {
  const { t } = useTranslation();
  const serverData = props && props.serverData ? props.serverData : false;
  const urlName = props && props.match && props.match.params && props.match.params.streamingTitle;
  const loader = <div className="loader container pr-0 streaming-loader"><div className="animated-background" /></div>;
  const [data, setData] = useState(serverData);
  const [dataMostReaded, setDataMostReaded] = useState();
  const [relateds, setRelateds] = useState();
  // const [middle1Loaded, setMiddle1Loaded] = useState('no-loaded-banner-mobile');
  const [middle2Loaded, setMiddle2Loaded] = useState('no-loaded-banner-mobile');
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [datenow, setDateNow] = useState();
  const [fairData, setFairData] = useState(null);
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  const { mkpUserData, userLoading } = useContext(UserContext);

  const breadcrumb = [
    {
      title: `${t('Streaming')}`,
      link: '/tv/streamings'
    },
  ];
  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
    getData({ url: `${url}/api/Streams/GetStreamData?url=tv/streamings/${urlName}` })
      .then(resp => {
        setData(resp.data)
        setIsLoading(false)
      });
  };

  const fetchDataRelated = () => {
    const url = window.env.RAZZLE_ENDPOINT_NOTI_API || process.env.RAZZLE_ENDPOINT_NOTI_API;
    getData({ url: `${url}/api/News/GetNewsListSummTagById?tid=${data.tag}&ini=0&size=10` })
      .then(resp => {
        setRelateds(resp.data || []);
      });
  };
  const fetchDataMostReaded = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    getData({ url: `${url}/api/News/GetMostRead?cant=5` })
      .then(resp => setDataMostReaded(resp.data));
  };

  useEffect(() => {
    window.onload = () => {
      setTimeout(() => {
        gtmInitialize();
      }, 3000)
    };
  }, []);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    if (urlName) {
      window.scrollTo(0, 0);
      fetchData();
      setData();
      fetchDataMostReaded();
    }
  }, [urlName]);

  useEffect(() => {
    if (urlName && data) {
      fetchDataRelated()
      setRelateds();
    }
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent)
    setIsMobile(isMobileDevice)
  }, [data, urlName]);

  useEffect(() => {
    const streamingDate = data && data.startDate;
    var d = new window.Date(streamingDate);
    setDateNow(d.getTime());
  }, []);

  useEffect(() => {
    if(window) {
      manageGTMPageName(`Home Streaming`);
    }
  }, []);

  const fairDesc = (fair) => {
    setFairData(fair);
  };

  const shareUrl = `https://news.agrofy.com.ar${props.location.pathname}`;
  const setStreamingTitle = () => serverData && serverData.title;
  const strImage = data && data.portada;
  const seoDesc = `En AgrofyTV vas a encontrar las mejores charlas, videos y streamings sobre ${setStreamingTitle()}. Entra a nuestra videoteca y ¡elegí la mejor opción para vos!`;

  const Completionist = () => <><Circle /><span className="category-page">Transmitiendo en vivo</span></>

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <div>Comienza en: {hours}:{minutes}:{seconds} hs</div>;
    }
  };

  // today date with reseted seconds:
  const todayDate = new Date();
  const resetTodayDateSec = todayDate.setSeconds(0, 0);
  const dateIso= todayDate.toISOString();

  // video date with reseted seconds:
  const videoDate = data && data.endDate && new Date(data.endDate);
  const resetVideoDateSec = data && data.endDate && videoDate.setSeconds(0, 0);
  const videodateIso= data && data.endDate && videoDate.toISOString();

  const isNotFinished = dateIso < videodateIso;

  const breadcrumbArr = [{level: 'Agrofy News'}, {level: 'Tags', urlLevel: 'tags/'}, {level: 'Trigo', urlLevel: 'trigo/'}];

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === 'Brasil';

  const carouselUrl = isBrasil ? "https://www.agrofy.com.br" : "https://www.agrofy.com.ar";

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <React.Fragment>
        <Helmet>
          <title>{`AgrofyTV | Streaming ${data && setStreamingTitle() || ''}`}</title>
          <meta name="robots" content="index,follow" />
          <meta name="description" content={seoDesc} />
          <link rel="canonical" href={shareUrl} />
          <meta property="og:url" content={shareUrl} />
          <meta property="og:title" content={`${data && setStreamingTitle() || ''} | Agrofy News`} />
          <meta property="og:image" content={strImage} />
          <meta property="og:description" content={seoDesc} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:site" content="@AgrofyNews" />
          <meta name="twitter:title" content={`${setStreamingTitle() || ''} | Agrofy News`} />
          <meta name="twitter:description" content={seoDesc} />
          <script src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          <script type="application/ld+json">{data && structuredVideoData(data, true)}</script>
          <script type="application/ld+json">{buildBreadcrumbJson(breadcrumbArr)}</script>
        </Helmet>
        <AgroPushNotification location="Section" />
        <section className="dfp-banner stripped container top-banner">
          <AdSlot adUnit={'streaming_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
        </section>
        <section className="breadcrumb-wrapper p-0 breadcrumb-streaming">
          <Breadcrumb breadcrumbLinks={breadcrumb} />
        </section>
        <LogoSection className="logo-section">
          {!data ? loader : <ContainerLogo>
            <div className="logo-section-content">
              <div className="live-video-cont">
                {data && data.isLiveBroadcast && isNotFinished && <Countdown
                  date={datenow}
                  renderer={renderer}
                />}</div>
              <h1 className="desktop-desc">{data.title}</h1>
            </div> <div><Link to="/tv"><img src={Logo} /></Link></div>
          </ContainerLogo>}
        </LogoSection>
        <MainVideoSection className="fluid-container main-video-section-container">
          <div>
            <Container pt="md" className="video-cont">
              <Row id="main-section" mt={"md"} className="streaming-content" px-md="xxs" px-sm="xxs">
                <Column lg={9} md={12} sm={12} className="main-section-col">
                  <StreamingCard data={data} loading={isLoading} isMobile={isMobile} />
                </Column>
                <Column lg={3} className="banner-column">
                  <div className="sticky-container">
                    <div className="make-me-sticky">
                      <AdSlot adUnit={'streaming_followme'} sizes={[[260, 600]]} />
                    </div>
                  </div>
                </Column>
              </Row>
            </Container>
          </div>
        </MainVideoSection>
        <Container pt="md" className="second-module-cont">
          <Row id="main-section" mt={"md"} className="streaming-content" px-md="xxs" px-sm="xxs">
            <Column lg={12} md={12} sm={12}>
              {(data && relateds) && <section id="facebook-comments" className="mt-20 fb-comments-container">
                <FbComments />
              </section>}
              {!data ? loader :
                <React.Fragment>
                  <div className="vd-container">
                    <div className="d-flex flex-wrap flex-space-between p-0 w-100 most-read-col">
                      <div id="newsletter" className="mb-36 p-0">
                        <NewsletterCardWithInput />
                        <div className='title-section-new  mt-36'><h3 className='main-title'>{t('most_read')}</h3></div>
                        <div className="d-flex most-readed-module">
                          {!dataMostReaded ? loader :
                            <MostReadedNew isColumn={false}/>
                          }
                          <div className={`m-full-width ${middle2Loaded} middle-str-banner`}>
                            <div className="dfp-banner stripped">
                              <AdSlot
                                adUnit={'streaming_middle_2'}
                                sizes={[[300, 250], [320, 50]]}
                                onSlotRender={e => e.event.isEmpty ? '' : setMiddle2Loaded('loaded-banner-mobile')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>}
            </Column>
            <Column lg={3} className="sticky-container" display-sm='none'>
              <div className="make-me-sticky">
                <AdSlot adUnit={'streaming_followme'} sizes={[[260, 600]]} />
              </div>
            </Column>
          </Row>
        </Container>
        <section id="marketplace-carousel" className="container my-68 my-m-36 pb-0">
          <div className="wrapper">
            <div className="search-wrapper d-flex flex-wrap">
              <div className="col-sm-3 col-xl-4 left-content">
                <a href={carouselUrl} target="_blank"><i className="agrofy-logo"></i></a>
                <label className="carousel-header-text d-none d-xl-flex">{t('find_everything_for_your_field')}</label>
              </div>
              <div className="col-sm-9 col-md-6 col-xl-4"><SearchBar /></div>
            </div>
            <div className="carousel-wrapper">
              <div>
                {fairData !== null ? 
                <div className="fair-head">
                  <span className="fair-head-title">{fairData && fairData.Title}</span>
                  <span className="fair-head-link"><a href={`${carouselUrl}/${fairData && fairData.Link}`}>Ver ofertas</a></span>
                  <div className="fair-head-claim">
                    <span className="fair-head-claim-text">{fairData && fairData.Claim}</span>
                  </div>
                </div> : null
                }
                <Carousel searchStr={data && data.carrusel} fairDesc={fairDesc}/>
              </div>
            </div>
          </div>
        </section>
        <section id="related" className="mt-36 mb-36 mt-lg-36 mb-lg-32">
          {!relateds ? loader : <RelatedSection relatedNews={relateds.news.slice(0, 10)} />}
        </section>
        <AdhesionBanner adUnit='streaming_adhesion' sizes={[[320, 80]]} />
      </React.Fragment>
    </DFPSlotsProvider>
  )
}

Streaming.getInitialProps = async ({ match, req, res }) => {
  const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
  const streamingTitle = match.params.streamingTitle;
  try {
    const {
      data
    } = await getData({ url: `${url}/api/Streams/GetStreamData?url=tv/streamings/${streamingTitle}` });
    return { serverData: data };
  } catch (e) {
    return { something: 'else' };
  }
};

export default withSSR(Streaming);


const MainVideoSection = styled.section`
  background: #212529;
`;