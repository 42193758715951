import React, {useState, useEffect, useContext} from 'react';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import {DFPSlotsProvider, AdSlot} from 'react-dfp';
import getData from '../../services/GetData';
import {Helmet} from 'react-helmet-async';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import SectionTemplate from '../../templates/sectionTemplate/SectionTemplate';
import {useTranslation} from 'react-i18next';
import withSSR from '../../withSSR';
import {gtmInitialize, manageGTMPageName, manageGTMUserStatus} from './../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import UserContext from '../../atoms/UserContext/UserContext';

const ExpoAgro = props => {
  const {t} = useTranslation();
  const serverData = props && props.serverData;
  const [updatedData, setUpdatedData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [seoTitle, setSeoTitle] = useState();
  const [seoText, setSeoText] = useState();
  const amount = (serverData && serverData.pages);
  const loader = <div className="loader container p-0"><div className="animated-background" style={{height: '1050px'}}/></div>;
  const tagName = (serverData && serverData.title_section && serverData.title_section.replace(/^\w/, c => c.toUpperCase())) || '';
  
  const dfpNetworkID = '21735159925';
  const dfpPrefix = "agrofynews_especificos/expoagro";

  const templateBanners = [
    {
      adUnit: 'expoagro_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'expoagro_middle_2',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'expoagro_middle_3',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'expoagro_filmstrip',
      sizes: [[300, 600]]
    }
  ];

  const { mkpUserData, userLoading } = useContext(UserContext);

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true)
    return getData({url: `${url}/api/News/GetNewsListByTag?tag=tags/expoagro-2024&page=${currentPage - 1}&limit=15`})
      .then(result => {
        setUpdatedData(result.data);
        setSeoTitle(result && result.data && result.data.title_section);
        setSeoText(result && result.data && result.data.seoText);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    fetchData();
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if ((updatedData || serverData) && currentPage !== 0) {
      const section = document.getElementById('section-template');
      fetchData();
      if (section) {
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(window && serverData) {
      manageGTMPageName(`Home ${serverData.title_section}`);
    }
  }, [serverData]);

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <div className="expoagro container px-0">
        <Helmet>
          <title>{`${tagName} | Encontrá todo sobre ExpoAgro en Agrofy News`}</title>
          <meta name="robots" content="index,follow" />
          <link rel="canonical" href="https://news.agrofy.com.ar/expo/expoagro" />
          <meta name="description" content="Encontrá las principales noticias sobre ExpoAgro 2024 en Agrofy News! Infórmate sobre uno de los eventos más importantes de la industria agropecuaria!" />
        </Helmet>
        <AgroPushNotification location="Section" />
        <section className="dfp-banner stripped container top-banner">
          <AdSlot adUnit={`${dfpPrefix}_header`} sizes={[[970, 90], [970, 250], [320, 80]]} />
        </section>
        <section id="main-section" className="d-flex flex-wrap mt-20">
          <div className="fluid-container col-lg-9 px-0">
            <section className="expoagro container">
              <h1>{seoTitle}</h1>
              <p style={{marginBottom:'40px'}}>{seoText}</p>
              <SectionTemplate 
                hasVideo={false}
                videoCategory='28473'
                data={updatedData || serverData}
                classes="small-spacing-top"
                bannersData={templateBanners}
                loading={isLoading}
                isExpo={true}
              />
              <div className="mb-36">
                {currentPage && amount &&
                  <PaginationAgrofy
                    parentCallback={setCurrentPage}
                    currentPage={currentPage}
                    amount={amount}
                  />
                }
              </div>
            </section>
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <AdSlot adUnit={`${dfpPrefix}_followme`} sizes={[[260, 600]]} />
            </div>
          </div>
        </section>
        <AdhesionBanner adUnit={`${dfpPrefix}_adhesion`} sizes={[[320, 80]]} specialNetworkID={dfpNetworkID}/>
        <PopUpBanner adUnit={`${dfpPrefix}_fullscreen`} sizes={[[800, 600], [320, 480]]} specialNetworkID={dfpNetworkID}/>
      </div>
    </DFPSlotsProvider>
  );
};

ExpoAgro.getInitialProps = async ({match, req, res}) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  try {
    const {
      data
    } = await getData({url: `${url}/api/News/GetNewsListByTag?tag=tags/expoagro-2024&page=0&limit=15`});
    return {serverData: data};
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(ExpoAgro);
